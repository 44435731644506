import { Component, OnInit } from '@angular/core';
import { MailService } from '../../../_common/services/mail.service';
import { ContactForm } from '../../../_common/models/contact-form';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare let $: any;

@Component({
    selector: 'contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    year: number;
    sending: boolean;
    submitted: boolean;
    error: boolean;
    contactForm: FormGroup;

    constructor (private mailService: MailService, private formBuilder: FormBuilder) {
        let date = new Date();

        this.submitted = false;
        this.sending = false;
        this.error = false;
        this.year = date.getFullYear();
    }

    ngOnInit() {
        this.contactForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            message: ['', Validators.required]
        });
    }

    get name() { 
        return this.contactForm.get('name');
    }

    get email() { 
        return this.contactForm.get('email');
    }

    get message() { 
        return this.contactForm.get('message');
    }

    onSubmit() {
        if (this.contactForm.invalid) {
            return;
        }

        this.error = false;
        this.sending = true;

        this.mailService.sendContactForm(this.contactForm).subscribe((response: any) => {
            this.sending = false;

            if (response.success) {
                this.submitted = true;
            } else {
                this.error = true;
            }
        });
    }
}