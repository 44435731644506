import { ElementRef, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { TweenMax, Linear, Power2 } from 'gsap';
import { MenuItem } from '../../models/menu-item';
import { NavigationService } from '../../services/navigation.service';

declare let ScrollMagic: any;
declare let $: any;

@Component({
  selector: 'top-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @ViewChild('header') header: ElementRef;

    controller: any;
    mobileMenuActive: boolean;
    menuItems: MenuItem[];

    constructor (private cdRef: ChangeDetectorRef, public navigationService: NavigationService) {
        this.menuItems = [
            new MenuItem('me', '#about'),
            new MenuItem('story', '#latest'),
            new MenuItem('skills', '#skills'),
            new MenuItem('contact', '#contact')
        ];
        this.mobileMenuActive = false;
    }

    ngOnInit () {
        $(document).ready(() => {
            $('#nav-icon').click(this.toggleMenu.bind(this));

            this.navigationService.setItems(this.menuItems);
            this.navigationService.getItems().subscribe(this.updateItems.bind(this));

            this.initPinHeader();
        });
    }

    updateItems (menuItems) {
        this.menuItems = menuItems;
        this.cdRef.detectChanges();
    }

    initPinHeader () {
        let controller = this.navigationService.getController();

        new ScrollMagic.Scene({
            offset: 1
        })
        .setPin('#header', {pushFollowers: false})
        .setTween('#menu', 0, {css: {className: '+= visible'}, reverse: true})
        .addTo(controller);
    }

    toggleMenu () {
        this.mobileMenuActive = !this.mobileMenuActive;

        if (!this.mobileMenuActive) {
            TweenMax.to('#menu', 0.8, { height: 0, ease: Power2.easeOut });
        } else {
            TweenMax.to('#menu', 0.8, { height: '100%', ease: Power2.easeOut });
        }

        $('#nav-icon').toggleClass('open');
    }

    navigate (evt, menuItem:MenuItem) {
        evt.preventDefault();

        if (this.mobileMenuActive)
            this.toggleMenu();

        this.navigationService.navigate(menuItem)
    }

    navigateToAnchor (evt, anchor) {
        evt.preventDefault();

        if (this.mobileMenuActive)
            this.toggleMenu();

        this.navigationService.navigateToAnchor(anchor)
    }
}