import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { TweenMax, TimelineMax, Back } from 'gsap';
import * as Splitting from 'splitting';

declare let $: any;
declare let ScrollMagic: any;
declare let SplitText: any;

@Component({
    selector: 'about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    @ViewChild('text') text:ElementRef;

    isMobile: boolean;

    page = {
        animation: 'Hi! I’m <u>Ferdi</u>,<br> I create user-centered<br>web applications.',
        title: 'Tech Lead | Full-Stack Developer | Software Engineer'
    }

    constructor () {}

    ngOnInit () {
        $(document).ready(() => {
            this.isMobile = window.innerWidth > 640 ? false : true;

            let tl = new TimelineMax();

            if (!this.isMobile) {
                let splitted = Splitting({
                    target: '#intro'
                });

                tl.staggerFrom(splitted[0].chars, 0.5, {visibility: 'hidden'}, 0.1, '+=0');
                tl.from('#summary', 1, {opacity: 0, top: 100}, '-=1');
            } else {
                tl.from('#summary', 1, {opacity: 0, top: 100});
            }

            tl.play();
        })   
    }
}