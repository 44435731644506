import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainModule } from './modules/main/main.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './modules/_common/components/header/header.component';

import { NavigationService } from './modules/_common/services/navigation.service';
import { MailService } from './modules/_common/services/mail.service';

import { HttpClientModule } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestore, FirestoreSettingsToken } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import 'foundation-sites';

const appRoutes: Routes = [
  { path: '',   redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MainModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    }),
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [
    AngularFirestore, 
    { provide: FirestoreSettingsToken, useValue: {} }, 
    NavigationService, 
    MailService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
