import { Directive, ElementRef } from '@angular/core';
import { TweenMax } from 'gsap';
import * as truncate from 'html-truncate';

declare let ScrollMagic: any;
declare let $: any;

@Directive({
	selector: '[appCard]'
})
export class CardDirective {
    summaryEl: any;
    video: any;
	summary: string;

	constructor(private el: ElementRef) {
        $(el.nativeElement).ready(() => {
            this.init();
        });
	}

    init() {
        let nativeEl = $(this.el.nativeElement);

        this.summaryEl = nativeEl.find('.summary');
        this.video = nativeEl.find('video').get(0);

        this.summary = this.summaryEl.html();

        this.closeSummary();
        this.truncateSummary();

        nativeEl.hover(this.openSummary.bind(this), this.closeSummary.bind(this));
    }

    closeSummary() {
        new TweenMax.to(this.summaryEl, 0.5, { css: {className: '+=closed' }, onComplete: () => {
            this.truncateSummary();
        }});

        if (this.video)
            this.video.pause();
    }

    truncateSummary() {
        this.summaryEl.html(truncate(this.summary, 85, {
            ellipsis: '...<span style="font-weight: 600;">read more</span>'
        }));
    }

    openSummary() {
        this.summaryEl.html(this.summary);

        new TweenMax.to(this.summaryEl, 0.5, { css: {className: '-=closed' }});

        if (this.video) {
            this.video.muted = "muted";
            this.video.play();
        }
    }
}