import { Component, OnInit } from '@angular/core';
import { PageService } from '../../_common/services/page.service';
import { TweenMax, Linear } from 'gsap';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs/rx';

declare let ScrollMagic: any;
declare let $: any;

@Component({
	templateUrl: './main.page.html',
	providers: [PageService],
	styleUrls: ['./main.page.scss']
})
export class MainPage implements OnInit {
	pageService: PageService;
	page: any;
	content: any;

	constructor (pageService: PageService) {
		this.pageService = pageService;
		this.page = {};
		this.content = {};
	}

	ngOnInit() {
		// let txt = $('#polaroid-1 .summary').html();
		// //$('#polaroid-1 .summary').html(String.truncate(txt, 110));

		// var figure = $("#polaroid-1").hover(function () {
		// 	let oldHeight = parseInt($('#polaroid-1 .summary').height());
		// 	//$('#polaroid-1 .summary').html(txt);
		// 	let newHeight = parseInt($('#polaroid-1 .summary').height());

		// 	console.log(oldHeight, newHeight);

		// 	new TweenMax.to('#polaroid-1 .description', 0.5, {height: '+=' + (newHeight - oldHeight)});
		//     $('video', this).get(0).play();
		// }, function () {
		// 	new TweenMax.to('#polaroid-1 .description', 0.5, {height: '8rem', onComplete: () => {
		// 		//$('#polaroid-1 .summary').html(String.truncate(txt, 110));
		// 	}});

		//     $('video', this).get(0).pause();
		// } );

		// let smallTxt = 'Rebuilding the search functionality of the main website and affiliated sites of Proximus...';

		// this.pageService.getPage('main').subscribe((page: any) => {
		// 	this.page = page;
		// 	this.content = this.page.content || {};
		// });

		// let controller = new ScrollMagic.Controller();

		// new ScrollMagic.Scene({
		// 		triggerElement: '#background',
		// 		triggerHook: 'onEnter',
		// 		duration: '200%'
		// 	})
		// 	.setTween('#background-img', {y: '80%', ease: Linear.easeNone})
		// 	.addTo(controller);

		

		// setTimeout(() => {
		// 	controller.scrollTo("#portfolio");
		// }, 1000)

		// let portfolioController = new ScrollMagic.Controller({container: '#portfolio'});

		// new ScrollMagic.Scene({
		// 		triggerElement: '#portfolio',
		// 		triggerHook: 'onCenter'
		// 	})
		// 	.setPin('#portfolio')
		// 	.addTo(portfolioController);
	}
}