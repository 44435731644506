import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ContactForm } from '../models/contact-form';
import { FormGroup } from '@angular/forms';

@Injectable()
export class MailService {

    constructor(private http: HttpClient) {}

    sendContactForm(contactForm: FormGroup) {
    	const httpOptions = {
    		headers: new HttpHeaders({
    			'Content-Type':  'application/json'
    		})
		};

        let data = Object.assign(contactForm.value, {
            domain: 'ferdiklomp.nl'
        });

		return this.http.post('https://us-central1-ferdiklompnl.cloudfunctions.net/sendMail', data, httpOptions);
    }
}