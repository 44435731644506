import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable()
export class PageService {
    db: AngularFirestore

    constructor(db: AngularFirestore) {
        this.db = db;
    }

    getPage(name: string) {
    	return this.db.collection('page').doc(name).valueChanges();
    }
}