export class MenuItem {
	title: string;
	anchor: string;
	active: boolean;

	constructor(title:string, anchor:string) {
		this.title = title;
		this.anchor = anchor;
		this.active = false;
	}
}