import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

    constructor () {}

    ngOnInit() {}
}