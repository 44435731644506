import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import { TweenMax } from "gsap";

@Component({
  selector: "timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"],
})
export class TimelineComponent implements OnInit {
  @ViewChild("#timeline") header: ElementRef;

  title = "STORY";
  description =
    '<p class="text-center">In 2010 I started my professional career, working on a freelance basis for various web design companies. After finishing my bachelor, I have worked for several years as a software engineer and developer in the Connected TV industry. With experience gathered through the years I was able to grow to a leading role in projects and scrum teams. </p><p class="text-center">Want to see what I have done lately?</p>';
  projects = [
    {
      date: "Sep 2022 - current",
      title: "Cineville",
      description:
        '<p><a href="https://www.cineville.nl/" target="_blank">Cineville</a> is rapidly expanding, I have helped them with building the <a href="https://www.cinevillepass.be/" target="_blank">cinevillepass.be</a> website and currently helping with renewing the cineville.nl website and app, backend for frontend services among other projects.</p><b>/ Typescript<br/>/ NextJS<br/>/ React (Native)<br/>/ React Query<br/>',
      media: {
        videos: [],
        backgroundStyle: {
          background: 'url("assets/img/cineville.jpeg")',
          "background-size": "cover",
        },
      },
    },
    {
      date: "Jun 2022 - Sep 2022",
      title: "Dept - StukTV",
      description:
        '<p>On behalf of <a href="https://www.deptagency.com/" target="_blank">Dept</a> I have worked on this short turnaround project for renewing the website and app for <a href="https://www.stuk.tv/" target="_blank">StukTV</a>, focusing on distributing their video content and improved user engagement.</p><b>/ Typescript<br/>/ NextJS<br/>/ React (Native)<br/>/ React Query<br/>',
      media: {
        videos: [],
        backgroundStyle: {
          background: 'url("assets/img/stuktv.png")',
          "background-size": "cover",
          "background-position": "center",
        },
      },
    },
    {
      date: "Jul 2021 - Jun 2022",
      title: "Dept - MijnKPN Zakelijk App",
      description:
        '<p>On behalf of <a href="https://www.deptagency.com/" target="_blank">Dept</a> I have worked on the new <a href="https://www.kpn.com/zakelijk/service/mijnkpnzakelijk-app.htm" target="_blank">MijnKPN Zakelijk app</a>. I was involved in this project from the first lines of code until the official launch of the app one year later.</p><b>/ Typescript<br/>/ React Native<br/>/ React Query<br/>/ Adobe Experience Platform</b>',
      media: {
        videos: [],
        backgroundStyle: {
          background: 'url("assets/img/mijnknp-zakelijk-app.png")',
          "background-size": "cover",
        },
      },
    },
    {
      date: "Mar 2020 - Jul 2021",
      title: "Triple - NLZiet",
      description:
        '<p>Helping <a href="https://www.wearetriple.com/" target="_blank">Triple</a> to kick-off their SmartTV Platform, with launching customer <a href="https://www.nlziet.nl/" target="_blank">NLZiet</a>. With my broad experience in the SmartTV branch I\'m helping <a href="https://www.wearetriple.com/" target="_blank">Triple</a> with research, software design decisions and development. Taking a lead role in the SmartTV team.</p><b>/ Typescript<br/>/ Svelte<br/>/ RxJS</b>',
      media: {
        videos: [
          {
            src: "assets/video/nlziet.mp4",
            type: "video/mp4",
          },
        ],
        backgroundStyle: {
          background: 'url("assets/img/nlziet.png")',
          "background-size": "cover",
        },
      },
    },
    {
      date: "Jul 2019 - Sep 2020",
      title: "doccs | de slimme aanpak voor betere zorg",
      description:
        '<p>Software architect and lead developer in the realisation of <a href="https://www.doccs.nl/" target="_blank">doccs</a>, a startup in the healthcare sector. Helped docss with bringing their idea from concept to working software. The project consisted of a back-end, mobile app and progressibe web app.</p><b>/ Typescript<br/>/ NodeJS<br/>/ Loopback<br/>/ React<br/>/ React Native<br/>/ Design Thinking</b>',
      media: {
        videos: [],
        backgroundStyle: {
          background: 'url("assets/img/doccs.png")',
          "background-size": "cover",
          "background-position": "center",
        },
      },
    },
    {
      date: "Jun 2018 - Feb 2019",
      title: "Proximus Enterprise Search",
      description:
        '<p>An enterprise search solution for the main website and affiliated sites of <a href="https://www.proximus.be/" target="_blank">Proximus</a>, the largest telecom company in Belgium. Fulfilled a leading role in the development team. Besides active development I was also responsible for functional and technical design decisions.</p><p>The solutions consists of crawling the contents of the websites and indexing in Elasticsearch. Implemented rich search functionalities on top the Elasticsearch.</p><b>/ Java<br/>/ Elasticsearch<br/>/ Spring Boot<br/>/ Apache ManifoldCF</b>',
      media: {
        videos: [
          {
            src: "assets/video/proximus.mp4",
            type: "video/mp4",
          },
        ],
        backgroundStyle: {
          background: 'url("assets/img/proximus.jpg")',
          "background-size": "cover",
        },
      },
    },
    {
      date: "Mar 2018 - May 2018",
      title: "Acerta Connect",
      description:
        '<p>Web and mobile app development for <a href="https://www.acerta.be/nl/werkgevers/kennis-en-applicaties/acerta-connect" target="_blank">Acerta</a>. The application helps companies manage their payroll. On the front-end new screens are developed for managing the calendar and employments, working together with a UI/UX partner. In the back-end new functionalities are developed for ingestion of payroll and employment data from XML to JSON, stored in MongoDB.</p><p>As a side project I also worked on a multi-threaded Java solution for importing contacts and training data into IBM Campaign.</p><b>/ HTML5, CSS3, Javascript<br/>/ NodeJS<br/>/ AngularJS<br/>/ Java<br/>/ Spring Boot</b>',
      media: {
        videos: [],
        backgroundStyle: {
          background: 'url("assets/img/connecteasy.png")',
          "background-size": "cover",
        },
      },
    },
    {
      date: "Dec 2017",
      title: "Craftworkz - Technical Lead",
      description:
        '<p>Started as technical lead in the Amsterdam office of Craftworkz. <a href="http://www.craftworkz.co" target="_blank">www.craftworkz.co</a></p>',
      media: {
        videos: [],
        backgroundStyle: {
          background: 'url("assets/img/craftworkznl.png")',
          "background-size": "80%",
          "background-repeat": "no-repeat",
          "background-position": "center",
        },
      },
    },
    {
      date: "Aug 2017 - Oct 2017",
      title: "Tennis Channel",
      description:
        '<p>An AppleTV application rebuild for <a href="http://thetennischannel.com/">Tennis Channel</a>. Integrated a new REST API back-end and Adobe Pass authentication flow. For the front-end a custom React(JSX) rendering mechanism was build to work with the AppleTV SDK\'s.</p> <b>/ Javascript<br/>/ React<br/>/ Adobe Pass<br/>/ Apple TV</b>',
      media: {
        videos: [
          {
            src: "assets/video/tennischannel.mp4",
            type: "video/mp4",
          },
        ],
        backgroundStyle: {
          background: 'url("assets/img/tennischannel.jpg")',
          "background-size": "cover",
        },
      },
    },
    {
      date: "Apr 2017 - Oct 2017",
      title: "SmartOTT Backstage",
      description:
        '<p>Lead in the development team responsible for realisation of the <a href="http://www.24i.com/press/24i-unveils-cutting-edge-cms-vod-smartott-backstage/" target="_blank">SmartOTT Backstage</a> product.</p><p>A video delivery platform with microservice architecture. Functionalities include data-sourcing, metadata managment and CMS components. Built with React, Laravel and ElasticSearch, deployed to Amazon AWS.</p><b>/ HTML5, CSS3, Javascript<br/>/ PHP<br/>/ React<br/>/ Laravel<br/>/ ElasticSearch<br/>/ Amazon AWS</b>',
      media: null,
    },
    {
      date: "Jun 2016 - Oct 2016",
      title: "FUSE",
      description:
        "<p>The AppleTV application for FUSE, a popular American TV network featuring music with concerts, music videos, interviews, and more.</p><p>Lead role in the implementation of the portable integration against the Comcast Video Platform. Other development activities included the Adobe Pass authentication and viewers analytics tracking through Google Analytics and Comcast.</p><b>/ HTML5, CSS3, Javascript<br/>/ Swift<br/>/ AppleTV<br/>/ Comcast Video Platform<br/>/ Adobe Pass<br/>/ Google Analytics<br/>/ Comscore</b>",
      media: {
        videos: [
          {
            src: "assets/video/fuse2.mp4",
            type: "video/mp4",
          },
        ],
        backgroundStyle: {
          background: 'url("assets/img/fuse.jpg")',
          "background-size": "cover",
        },
      },
    },
    {
      date: "Dec 2015",
      title: "24i - Software Developer",
      description:
        '<p><a href="https://www.24i.com/" target="_blank">24i</a> concepts, designs, develops, deploys and maintains TV apps for any screen: Smart TVs, set-top boxes, game consoles, tablets and mobiles.</p>',
      media: {
        videos: [],
        backgroundStyle: {
          background: 'url("assets/img/24i.png")',
          "background-size": "80%",
          "background-repeat": "no-repeat",
          "background-position": "center",
        },
      },
    },
    {
      date: "Dec 2013 - Nov 2015",
      title: "m:tel",
      description:
        '<p>Delivery of a full-blown IPTV platform for <a href="https://mtel.ba/n354/Customer" target="_blank">m:tel</a>, Bosnia. This includes the installation of bare-metal servers, hardware and software needed for video ingestion, encoding/transcoding, playout, load balancing, and more. Activities ranged from systems and networking engineering to software development.</p><p>In the project I grew from a junior engineer to a lead engineer. I was one of the main persons responsible for performing the FAT(factory acceptance test) at customer side. Gave training to the customer on the delivered IPTV systems and technologies. Actively developed custom IPTV applications using the FokusOn IPTV platform.</p><b>/ Systems & Network engineering<br/>/ HTML, CSS3, Javascript<br/>/ Nordija FokusOn</b>',
      media: {
        videos: [
          {
            src: "assets/video/mtel.mp4",
            type: "video/mp4",
          },
        ],
        backgroundStyle: {
          background: 'url("assets/img/mtel.jpeg")',
          "background-position": "cover",
        },
      },
    },
    {
      date: "Jul 2014 - Oct 2014",
      title: "ProvSys",
      description:
        "<p>Visual re-design and software development of the ProvSys product. ProvSys is a device management and provisioning system for CMTS/Cable Modems.</p><p>This includes a C++ based back-end for dynamically loading DHCP configurations. A HTML5/CSS3/Javascript front-end based on the Boostrap framework. And a PHP based REST-API for configuration of devices and provisioning parameters.</p><b>/ HTML5, CSS3, Javascript<br/>/ PHP<br/>/ C++<br/>/ jQuery<br/>/ Bootstrap<br/>/ Yii</b>",
      media: null,
    },
    {
      date: "Jul 2014 - Oct 2014",
      title: "Divitel - Software Engineer",
      description:
        '<p>Started as a software engineer with <a href="https://www.divitel.nl/" target="_blank">Divitel</a> after a successful internship and earning my bachelor\'s degree.</p><p>Divitel is a technical knowledge company and independent system integrator with a background in professional broadcast media</p>',
      media: {
        videos: [],
        backgroundStyle: {
          background: 'url("assets/img/divitel.png") #1D3786',
          "background-size": "80%",
          "background-repeat": "no-repeat",
          "background-position": "center",
        },
      },
    },
    {
      date: "Jan 2011 - Sep 2011",
      title: "Mediajunkies - Front-end Developer",
      description:
        '<p>Started as a freelance front-end developer for <a href="https://www.mediajunkies.nl/" target="_blank">Mediajunkies</a> as a result of my internship at 3FM. A Mediapark based company where I had the opportunity to work on websites for NPO, 3FM, and Radio4.</p><b>/ HTML5, CSS3, Javascript<br/>/ jQuery<br/>/ ABC Manager</b>',
      media: {
        videos: [],
        backgroundStyle: {
          background: 'url("assets/img/mediajunkies.jpg")',
          "background-size": "40%",
          "background-repeat": "no-repeat",
          "background-position": "center",
        },
      },
    },
    {
      date: "Sep 2010 - Jan 2011",
      title: "3FM - Front-end Developer",
      description:
        '<p>Internship at <a href="https://www.3fm.nl/" target="_blank">NPO 3FM</a>, dutch radio station. Built a framework and front-end application for indexing and playback of Youtube video content from various broadcasters such as VARA, VPRO, and more. The developed application has been the video section of the 3FM public websites for some years.</p><b>/ HTML5, CSS3, Javascript<br/>/ PHP<br/>/ ABC Manager<br/>/ Zend Framework</b>',
      media: {
        videos: [],
        backgroundStyle: {
          background: 'url("assets/img/3fm.jpg")',
          "background-size": "50%",
          "background-repeat": "no-repeat",
          "background-position": "center",
        },
      },
    },
    {
      date: "Sep 2009 - Aug 2010",
      title: "B302 - Front-end Developer",
      description:
        '<p>Worked as a front-end developer at <a href="https://www.b302.nl/" target="_blank">B302</a>, a company affiliated to the Hogeschool van Arnhem en Nijmegen. Worked on several web application projects.</p><b>/ HTML5, CSS3, Javascript<br/>/ PHP<br/>/ jQuery<br/>/ CakePHP</b>',
      media: {
        videos: [],
        backgroundStyle: {
          background: 'url("assets/img/buro302.jpg") #151419',
          "background-size": "50%",
          "background-repeat": "no-repeat",
          "background-position": "center",
        },
      },
    },
  ];
  visibleProjects = [];

  constructor() {
    this.visibleProjects = this.projects.slice(0, 2);
  }

  ngOnInit() {
    $("#latest .show-more").click((evt) => {
      evt.preventDefault();

      this.showMore();
    });
  }

  showMore() {
    $("#latest .show-more").hide();

    const length = this.visibleProjects.length;
    const height = $("#latest").outerHeight();

    TweenMax.set("#latest", { overflow: "hidden", height: height });

    this.visibleProjects = this.projects.slice(0, length + 2);

    TweenMax.set("#latest", { height: "auto" });
    TweenMax.from("#latest", 0.5, {
      css: { height: height },
      onComplete: () => {
        if (this.visibleProjects.length < this.projects.length) {
          $("#latest .show-more").show();
        }
      },
    });
  }
}
