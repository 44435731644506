import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MainPage } from './pages/main.page';
import { AboutComponent } from './components/about/about.component';
import { ParallaxBannerComponent } from './components/parallax-banner/parallax-banner.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { SkillsComponent } from './components/skills/skills.component';
import { ContactComponent } from './components/contact/contact.component';
import { CardDirective } from './directives/card.directive';

const appRoutes: Routes = [
  { path: '', component: MainPage }
];

@NgModule({
  declarations: [
    MainPage,
    AboutComponent,
    ParallaxBannerComponent,
    TimelineComponent,
    ContactComponent,
    SkillsComponent,
    CardDirective
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class MainModule { }
