import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { TweenMax, TimelineMax, Linear } from 'gsap';
import { NavigationService } from '../../../_common/services/navigation.service';

declare let ScrollMagic: any;
declare let $: any;

@Component({
    selector: 'parallax-banner',
    templateUrl: './parallax-banner.component.html',
    styleUrls: ['./parallax-banner.component.scss']
})
export class ParallaxBannerComponent implements OnInit {
    controller: any;

    constructor (public navigationService: NavigationService) {}

    ngOnInit () {
        $(document).ready(() => {
            this.initParallax();
        });
    }

    initParallax() {
        let controller = this.navigationService.getController();

        new ScrollMagic.Scene({
                triggerElement: '#background',
                triggerHook: 'onEnter',
                duration: '200%'
            })
            .setTween('#background-img', {y: '80%', ease: Linear.easeNone})
            .addTo(controller);
    }

    navigate(evt, anchor) {
        evt.preventDefault();

        this.navigationService.navigateToAnchor(anchor);
    }
}