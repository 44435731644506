// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDKIGeXLsNqas1g5f06w9XgV6QMaZdi7P4',
    authDomain: 'https://accounts.google.com/o/oauth2/auth',
    databaseURL: 'https://ferdiklompnl.firebaseio.com',
    projectId: 'ferdiklompnl',
    storageBucket: '<your-storage-bucket>',
    messagingSenderId: '<your-messaging-sender-id>'
  }
};