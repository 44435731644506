import { Injectable } from '@angular/core';
import { TweenMax, Linear, Power2 } from 'gsap';
import { Observable, BehaviorSubject } from 'rxjs';
import { MenuItem } from '../models/menu-item'

declare let ScrollMagic: any;
declare let $: any;

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	controller: any;
	navigationScene: any;
    isMobile: boolean;
	menuItems: BehaviorSubject<MenuItem[]>;

    constructor() {
        this.menuItems = new BehaviorSubject([]);
        this.isMobile = window.innerWidth > 640 ? false : true;
    }

    initNavigation(menuItems: MenuItem[]) {
        let controller = this.getController();

        menuItems.forEach((item, i) => {
            let prevItem = i > 0 ? menuItems[i-1] : null;
            let scene = new ScrollMagic.Scene({
                triggerElement: item.anchor,
                triggerHook: 'onCenter'
            })
            .on('start', () => {
                this.updateItems(item);
            });

            if (prevItem)
                scene.on('leave', () => {
                    this.updateItems(prevItem);
                });

            scene.addTo(controller);
        });
    }

    getController() {
        if (this.controller)
            return this.controller;

        let container = this.isMobile ? '#main-container' : window;

        this.controller = new ScrollMagic.Controller({
            container
        });

        this.controller.scrollTo(function (position) {
            TweenMax.to(container, 1, {scrollTo: {y: position}, ease: Power2.easeOut});
        });

        return this.controller;
    }

    updateItems(navItem:MenuItem) {
    	let menuItems = this.menuItems.getValue();

    	menuItems = menuItems.map((menuItem:MenuItem) => {
            if(menuItem.anchor === navItem.anchor) {
            	menuItem.active = true;
            } else {
            	menuItem.active = false;
            }

            return menuItem;
        });

        this.menuItems.next(menuItems);
    }

    navigate(navItem:MenuItem) {
    	this.controller.scrollTo(navItem.anchor);

    	this.updateItems(navItem);
    }

    navigateToAnchor(anchor:string) {
    	let menuItems = this.menuItems.getValue();

    	menuItems.forEach((menuItem: MenuItem) => {
    		if(menuItem.anchor === anchor)
    			this.navigate(menuItem);
    	});
    }

    setItems(menuItems:MenuItem[]) {
    	this.menuItems.next(menuItems);
    	this.initNavigation(menuItems);
    }

    getItems(): Observable<MenuItem[]> {
    	return this.menuItems;
    }
}